import styled from "styled-components"

const GoBack = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.blue};
    display: block;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.sm};
    font-weight: bold;
    margin-top: ${theme.spaces[3]};
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  `}
`

export default GoBack
