import React from "react"
import styled from "styled-components"
import FoldContainer from "./FoldContainer"
import MarginContainer from "./MarginContainer"
import SectionHeader from "./SectionHeader"
import ShortHero from "./ShortHero"
import { navigate } from "gatsby"
import GoBack from "./GoBack"

const ShortHeroText = styled.span`
  ${({ theme }) => `
    align-items: center;
    color: white;
    display: flex;
    font-family: ${theme.fonts.andellia};
    font-size: 3rem;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (min-width: ${theme.breakpoints[2]}){
      font-size: 6rem;
    }
  `}
`

const VideoContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spaces[1]};
    width: 100%;
  `}
`

const Video = styled.div`
  ${({ theme }) => `
    display: block;

    & > div {
      color: ${theme.colors.blue};
      font-family: ${theme.fonts.proxima};
      font-size: ${theme.fontSize.sm};
      font-weight: bold;
      margin-bottom: ${theme.spaces[0]};
      margin-top: ${theme.spaces[0]};
      text-transform: uppercase;
    }
  `}
`

const VideoFrame = styled.iframe`
  ${({ theme }) => `
    height: 300px;
    width: 100%;
    
    @media (min-width: ${theme.breakpoints[2]}){
      height: 600px;
    }
  `}
`

const CustomSectionHeader = styled(SectionHeader)`
  font-size: 4rem;
`

const VideoPage = ({ videos, title }) => (
  <>
    <ShortHero>
      <ShortHeroText>Through The Years</ShortHeroText>
    </ShortHero>
    <FoldContainer>
      <MarginContainer>
        <GoBack onClick={() => navigate("/through-the-years")}>
          &lt;&nbsp;Go Back
        </GoBack>
        <CustomSectionHeader showLine={false}>{title}</CustomSectionHeader>
        <VideoContainer>
          {videos.map(({ name, link }) => (
            <Video key="link">
              <div>{name}</div>
              <VideoFrame
                width="100%"
                height="600px"
                src={link}
                scrolling="no"
                frameBorder="0"
                allowFullScreen
              />
            </Video>
          ))}
        </VideoContainer>
      </MarginContainer>
    </FoldContainer>
  </>
)

export default VideoPage
