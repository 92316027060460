import React from "react"
import VideoPage from "../../components/VideoPage"

const data = [
  {
    name: "UP Med Funked Me Up",
    link: "https://www.youtube.com/embed/SUitcn1JdOQ",
  },
]

const FunkedMeUpPage = () => <VideoPage videos={data} title="" />

export default FunkedMeUpPage
